import { isAvailableCountry } from 'constants/country-locale-list';
import React from 'react'
import Layout from "layouts/Layout";
import HomepageTemplate from "templates/HomepageTemplate";
import { redirectCountryRootPage } from "services/navigation.service";
import { getUserIpDetail } from 'services/locale.service';


export default function CountryRootPage() {
  return (
    <Layout scrollable={true} showFooter={true} className='w-11/12 cd:max-w-[1311px] xl:max-w-[1130px] mx-auto'>
      <HomepageTemplate />
    </Layout>
  )
}

export const getServerSideProps = async ({ req, params, locale = 'en' }) => {
  const { country: countryP } = params;

  if (!isAvailableCountry(countryP)) {
      return redirectCountryRootPage( { req , locale } ); 
  }

  const ipData = await getUserIpDetail();

  return {
    props: {
      isWspExpanded: true,
      ipData
    }
  };
};